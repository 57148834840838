<template>
  <div class="subscriptions-content">
    <a-row class="d-flex align-items-center mb-4" style="margin: -30px; padding: 22px 30px; background: #fff;">
      <a-col :span="18">
        <h3 class="my-2" style="font-size: 20px;">Match Events Subscriptions</h3>
      </a-col>
    </a-row>
    <a-card class="mt-2">
      <a-row type="flex" class="olimp-admin-auth pb-4">
        <a-col flex="250px" class="pr-2" style="box-sizing: border-box !important; font-size: 16px !important">
          <span class="pr-2" style>Search Field:</span>
          <a-select
            v-model="searchField"
            @change="handleSearchField"
            :default-value="searchFields[0]"
            style="width: 140px"
          >
            <a-select-option v-for="sf in searchFields" :key="sf" :value="sf">
              {{ sf[0].toUpperCase() + sf.slice(1).replace('_', ' ').replace('ido', 'ID') }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col flex="auto">
          <a-input-search size="large" v-model="searchValue" placeholder="input search text" enter-button @search="onSearch"/>
        </a-col>
      </a-row>
      <a-table
        :columns="subscriptionColumns"
        :row-key="record => record.id"
        :data-source="subscriptionData"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        class="subscriptions-table"
      >
        <span slot="type" slot-scope="type">
          <a-icon v-if="type==='favorite'" type="star" class="text-warning" />
          <a-icon v-if="type==='lite'" type="tag" class="text-primary" />
          <a-icon v-if="type==='full'" type="tags" class="text-success" />
          {{ type[0].toUpperCase() + type.slice(1) }}
        </span>
        <span slot="starts_at" slot-scope="starts_at">
          {{ $moment(starts_at).format('DD.MM.YY HH:mm') }}
        </span>
        <span slot="updated_at" slot-scope="updated_at">
          {{ $moment(updated_at).format('DD.MM.YY HH:mm') }}
        </span>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'

const subscriptionColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    sorter: true,
    width: '7%',
    scopedSlots: { customRender: 'id' },
  },
  {
    title: 'Type',
    dataIndex: 'type',
    width: '10%',
    filters: [
      { text: 'Favorites', value: 'favorite' },
      { text: 'Lite', value: 'lite' },
      { text: 'Full', value: 'full' },
    ],
    scopedSlots: { customRender: 'type' },
  },
  {
    title: 'Login',
    dataIndex: 'login',
    sorter: true,
    width: '8.5%',
    scopedSlots: { customRender: 'login' },
  },
  {
    title: 'Match ID',
    dataIndex: 'match_ido',
    width: '8.5%',
    scopedSlots: { customRender: 'match_ido' },
  },
  {
    title: 'Match Name',
    dataIndex: 'match_name',
    width: '20%',
    scopedSlots: { customRender: 'match_name' },
  },
  {
    title: 'Champ Name',
    dataIndex: 'champ_name',
    width: '20%',
    scopedSlots: { customRender: 'champ_name' },
  },
  {
    title: 'Match Starts At',
    dataIndex: 'starts_at',
    width: '10%',
    scopedSlots: { customRender: 'starts_at' },
  },
  {
    title: 'Last updated',
    dataIndex: 'updated_at',
    width: '10%',
    scopedSlots: { customRender: 'updated_at' },
  },
]

export default {
  name: 'index',
  components: {
    //
  },
  mixins: [accessMix],
  computed: {
    ...mapState(['user']),
  },
  data() {
    return {
      accessModule: 'subscriptions',
      subscriptionData: [],
      pagination: { pageSize: 25 },
      loading: false,
      subscriptionColumns,
      searchField: 'login',
      searchFields: ['login', 'match_ido', 'match_name'],
      searchStrictFields: ['login', 'match_ido'],
      searchValue: '',
    }
  },
  mounted () {
    this.fetch()
  },
  created() {
    //
  },
  methods: {
    handleSearchField() {
      console.log(this.searchField)
      this.searchValue = ''
    },
    onSearch() {
      if (!this.searchStrictFields.includes(this.searchField) && (this.searchValue.length < 6)) {
        this.$message.error('Search value of this field must contain at least 6 characters')
      } else {
        this.fetch({ searchField: this.searchField, searchValue: this.searchValue })
      }
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
        searchField: this.searchField,
        searchValue: this.searchValue,
      })
    },
    fetch(params = {}) {
      console.log('params:', params)
      this.loading = true
      const reqData = { results: 25, ...params }
      console.log(reqData)
      const url = '/admin/subscriptions/matches'
      apiClient.get(url, { params: reqData }).then((response) => {
        const pagination = { ...this.pagination }
        pagination.total = response.data.meta.total
        this.loading = false
        this.subscriptionData = response.data.data
        this.pagination = pagination
      }).catch(error => {
        console.log(error)
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss">
@media (max-width: 1600px) {
  .subscriptions-table {
    .ant-table-thead, .ant-table-tbody  {
      font-size: 0.9em;
    }
  }
}
@media (max-width: 1500px) {
  .subscriptions-table {
    .ant-table-thead, .ant-table-tbody  {
      font-size: 0.8em;
    }
  }
}
</style>
